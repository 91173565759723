<template>
  <div class="notification">
    <div class="page-header">
      <p>
        <strong>通知列表</strong>
      </p>
    </div>
    <div class="notification-header">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.title"
          :name="item.name"
          v-for="(item, index) in tabList"
          :key="item.name"
        ></el-tab-pane>
      </el-tabs>
      <div class="table-center" v-loading="listLoading">
        <div
          class="table-center-notification"
          @mouseleave="leaveShow()"
          v-if="notificationList.page.total > 0"
        >
          <div
            class="table-center-notification-item"
            @mouseenter="changeIndex(index)"
            v-for="(notList, index) in notificationList.list"
            :key="index"
            :class="{ redHuiBack: curentIndex == index }"
          >
            <div class="table-center-notification-item-left">
              <!-- 平台   -->
              <div class="table-center-notification-item-left-border">
                <img src="@/assets/avatar-circle.png" alt="" />
                <!--                <Isvg icon-class="logo-main" />-->
                <i class="iconfont icon-point wh-10 b" v-show="notList.status == 1"></i>
              </div>
            </div>
            <div class="table-center-notification-item-right">
              <div class="table-center-notification-item-right-header">
                <div class="table-center-notification-item-right-header-left">
                  <el-tooltip class="item" effect="dark" placement="top">
                    <div slot="content">
                      {{ notList.title }}
                    </div>
                    <span class="f-14 maxLength">{{ notList.title }}</span>
                  </el-tooltip>
                  <span class="h-57 f-12 ml-20">{{ notList.send_at }}</span>
                </div>
                <div
                  class="table-center-notification-item-right-header-right"
                  v-show="curentIndex == index"
                >
                  <el-button
                    v-if="notList.jump.pc_url"
                    class="f-12 h-57 b-font hover-blue messageRead-button"
                    type="text"
                    style="padding: 0"
                    @click="jumpNotificationUrl(notList)"
                    :class="{ redHui: notList.status == 2 }"
                    >{{ '点击查看' }}
                  </el-button>
                  <el-button
                    class="f-12 h-57 b-font hover-blue messageRead-button"
                    type="text"
                    style="padding: 0"
                    @click="getNotificationIsRead(notList)"
                    :class="{ redHui: notList.status == 2 }"
                    >{{ notList.status == 1 ? '标为已读' : '已读' }}
                  </el-button>
                  <el-popconfirm
                    :title="`您确定删除${notList.user_name}的信息吗?`"
                    @confirm="deleteNotfi(notList.id)"
                  >
                    <el-button
                      slot="reference"
                      type="text"
                      style="padding: 0"
                      class="f-12 h-57 hover-blue messageRead-button"
                      :class="{ redHui: notList.status == 2 }"
                    >
                      {{ '删除' }}
                    </el-button>
                  </el-popconfirm>
                </div>
              </div>
              <!--    底部内容不同状态的展示 分为单行多行全展开   -->
              <div class="table-center-notification-item-right-bottom">
                <div class="table-center-notification-bottom-message">
                  <ul :class="{ redBlack: noRead, redHui: isRead }">
                    <li>{{ notList.content }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="block" v-show="notificationList.page.page_num > 1">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="notificationList.page.page"
              :page-size="notificationList.page.page_size"
              :page-sizes="[3, 5, 10]"
              layout="prev, pager, next,->,total,jumper"
              :total="notificationList.page.total"
              background
              :hide-on-single-page="true"
            ></el-pagination>
          </div>
        </div>
        <el-empty v-else description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
  import throttle from 'lodash/throttle';
  import {
    reqestNotifiList,
    reqestNotifiRead,
    requestNotifiInfo,
    requsetNotifiDel
  } from '@/api/notification';
  export default {
    name: 'notification',
    created() {
      const type = this.$route.query.type
      if(type) {
        this.searchParams.type = type
        this.activeName = type
      }
    },
    mounted() {
      this.getNotifiList(this.searchParams);
    },
    data() {
      return {
        listLoading: false,
        activeName: 'all',
        noRead: true,
        isRead: false,
        noReadBack: true,
        isReadBack: false,
        curentIndex: -1,
        tabList: [
          {
            title: '全部通知',
            name: 'all'
          },
          {
            title: '任务',
            name: 'task'
          },
          {
            title: '账户',
            name: 'wallet'
          },
          {
            title: '系统',
            name: 'system'
          }
        ],
        searchParams: {
          page: 1,
          page_size: 10,
          kw: '',
          type: 'all'
        },
        notificationList: {
          list: [],
          page: {
            page: 1,
            page_size: 5,
            limit: 5,
            total: 0
          }
        }
      };
    },
    methods: {
      //获取通知详情
      async getNotificationInfo(id) {
        let notificationinfo = await requestNotifiInfo({ id });
        console.log(notificationinfo);
      },
      //获取通知列表
      async getNotifiList(data) {
        this.listLoading = true;
        let notificationDta = await reqestNotifiList(data);
        if (notificationDta.statusCode === 200) {
          //this.notificationList = notificationDta;
          this.notificationList.list = notificationDta.data.list;
          this.notificationList.page = notificationDta.data.page;
          //console.log(this.notificationList.list)
        }
        this.listLoading = false;
      },
      handleClick(tab, event) {
        this.searchParams.page = 1;
        this.searchParams.type = tab.name;
        this.getNotifiList(this.searchParams);
      },
      async deleteNotfi(id) {
        let json = await requsetNotifiDel({ id });
        if (json.statusCode === 200) {
          this.notificationList.list.splice(
            this.notificationList.list.findIndex((item) => item.id == json.data.id),
            1
          );
        } else {
          this.$message(json.message);
        }
      },
      changeIndex: throttle(function (index) {
        this.curentIndex = index;
      }, 20),
      leaveShow() {
        this.curentIndex = -1;
      },
      handleCurrentChange(val) {
        this.searchParams.page = val;
        this.getNotifiList(this.searchParams);
      },
      async getNotificationIsRead(notList) {
        const res = await reqestNotifiRead({ ids: notList.id });
        if (res.statusCode == 200) {
          notList.status = 2;
        }
      },
      jumpNotificationUrl(value) {
        const { pc_url: url } = value.jump;
        location.href = url;
      }
    }
  };
</script>

<style scoped lang="less">
  .page-header {
    height: 76px;
    border-bottom: 1px solid #b5b5b5;
    display: flex;
    p {
      font-size: 18px;
      margin: 40px;
    }
  }
  .notification {
    background: #fff;
    min-height: 930px;
    .notification-header {
      /deep/.el-tabs__header {
        padding-top: 30px;
      }
      /deep/#tab-first,
      /deep/#tab-second,
      /deep/#tab-third,
      /deep/#tab-fourth,
      /deep/#tab-fifth {
        // text-align: center;
        // width: 120px;
      }
      /deep/.el-tabs__active-bar {
        // width: 120px !important;
        text-align: center;
      }
      /deep/.el-tabs__item {
        padding: 0;
      }
      /deep/.el-tabs__header {
        margin: 0;
      }
      .table-center-notification {
        .table-center-notification-item {
          cursor: pointer;
          width: 940px;
          display: flex;
          border-bottom: 1px solid #b5b5b5;
          padding-top: 20px;
          .table-center-notification-item-left {
            margin-left: 40px;
            .table-center-notification-item-left-border {
              width: 48px;
              height: 48px;
              position: relative;
              img {
                width: 48px;
                height: 48px;
              }
              i {
                position: absolute;
                top: 0px;
                left: 0px;
              }
            }
          }
          .table-center-notification-item-right {
            margin-left: 20px;
            margin-right: 40px;
            //右侧头部内容
            .table-center-notification-item-right-header {
              width: 788px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 18px;
              .table-center-notification-item-right-header-left {
                width: 600px;
              }
              .table-center-notification-item-right-header-right {
                //width: 100px;
                display: flex;
                height: 16px;
                align-items: center;
                justify-content: space-between;
                .messageRead-button {
                  font-size: 12px;
                  margin-right: 10px;
                }
                /deep/.messageReadIcon {
                  i {
                    font-size: 12px !important;
                  }
                }
                /deep/ .el-popover__reference-wrapper span {
                  font-size: 12px !important;
                }
              }
              .maxLength {
                max-width: 100px;
              }
            }
          }
          //右侧底部内容
          .table-center-notification-item-right-bottom {
            margin-top: 20px;
            padding-bottom: 20px;
          }
          //  多行加链接的样式
          .table-center-notification-bottom-secondFont-link {
            ul {
              li {
                vertical-align: center;
                line-height: 15px;
                margin-bottom: 10px;
                width: 100%;
              }
            }
          }
          //  通知信息
          .table-center-notification-bottom-message {
            margin-top: 20px;
            p {
              font-size: 12px;
              margin-top: 10px;
              color: #575757;
            }
            ul {
              span {
                display: block;
                margin-top: 10px;
              }
              li {
                margin-top: 10px;
                font-size: 12px;
                line-height: 22px;
              }
            }
          }
          //通知信息 单行少文本
          .table-center-notification-bottom-message-less {
            font-size: 12px;
            ul {
              li {
                &:not(.congratulate) {
                  margin-top: 20px;
                }
              }
            }
          }
          //投标通知
          .table-center-notification-bottom-user-second-warp {
            li {
              line-height: 22px;
              font-size: 12px;
            }
          }
          //
          .table-center-notification-bottom-user-one-link {
          }
        }
      }
    }
  }
  .table-center {
    min-height: 700px;
  }
  //共有的样式
  .redBlack {
    color: #000;
  }
  .redHui {
    width: 50px;
    color: #575757 !important;
    opacity: 0;
  }
  .redBlackBack {
    background-color: #fff;
  }
  .redHuiBack {
    background-color: #f4f4f4;
  }
  .redHuiBack2 {
    background-color: #f4f4f4;
  }
  ul {
    li {
      color: #575757;
    }
  }
  .block {
    padding: 20px 0;
  }
  .messageReadIcon:hover {
    color: #00a2e6 !important;
  }
  .b-font {
    width: 50px;
    text-align: right;
  }
  .hover-blue:hover {
    color: #00a2e6;
  }

  /deep/.el-button--text {
    color: #575757;
  }
  /deep/.el-tabs__item {
    width: 130px;
    text-align: center;
  }
</style>
