import request from './ajax'
import qs from 'qs'
// 通知列表
export const reqestNotifiList = (data) => request({
    method: "get",
    url: "/uc/notice",
    params: data,
})

// 读取通知
export const reqestNotifiRead = (data) => request({
    method: "post",
    url: "/uc/notice/read",
    data: qs.stringify(data),
})

// 通知详情
export const requestNotifiInfo = (data) => request({
    method: "get",
    url: "/uc/notice/info",
    params:data,
})
//删除通知
export const requsetNotifiDel = (data) => request({
    method:'post',
    url:"/uc/notice/delete",
    data:qs.stringify(data)
})